/* You can add global styles to this file, and also import other style files */

@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
// Vex Core
@import "./@vex/styles/core";

h2 {
  margin: 0px !important;
}
/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --sidenav-background: #333333;
  }

  All possible variables can be found in @vex/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
:root {
  .max-w-xs {
    max-width: 25rem;
  }
  .mt-6{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  // --color-primary: blue;
  // --color-primary-contrast: white;

  // --color-accent: yellow;
  // --color-accent-contrast: black;

  // --color-warn: yellow;
  // --color-warn-contrast: black;
}
.example-loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type="file"]{
  //width:50%;
  height:100%;
  position:absolute;
  top:0;
  left:0;
  opacity:0;
  cursor:pointer;
}
input.mat-input-element {
  height: 20px !important;
}
.mat-select-value {
  height: 20px !important;
}
